import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResultDrJudges from './ResultDrJudges'
import ResultDrJudgesTable from './ResultDrJudgesTable'
import NumberFormat from 'react-number-format';

class TableLine extends React.Component {
    statut = (entry) => {
        return(entry.status)
    }
    mark = (entry) => {
        if (entry.result){
            return (<>{entry.result.Moyenne > 0 ? <><NumberFormat 
                value={entry.result.Moyenne}
                displayType={'text'}
                decimalScale={3}
            /> % ({entry.Classement}.)</>:<em>{entry.startTime}</em>}
            <br/>
                {this.props.nbjudges > 3 ?
                    <ResultDrJudgesTable entry={entry} />
                :
                    <ResultDrJudges entry={entry} />
                }
            </>)
        } else {
            return (null)
        }
    }
    render(){
    let champLive
    var entry 
    var datasLive
    if (this.props.champLive){
        champLive = JSON.parse(this.props.champLive)
        if (champLive[this.props.type]){
            entry = JSON.parse(champLive[this.props.type])
            if (entry){
                entry = entry[0]
                if (entry){
                    entry.result = JSON.parse(entry.results)
                }
            }
        }
    }
    if (this.props.datasLive){
        if (this.props.datasLive.live){
            datasLive = JSON.parse(this.props.datasLive.live)
            if (datasLive){
                datasLive = datasLive[0]
            }
            
            if (datasLive.results){
                datasLive = JSON.parse(datasLive.results)
            }
        }
    }
    return(
        <>
        {entry &&
        <Container fluid className="table-last">
        <Row >
            <Col xs={12} className="last-title">   
                {this.props.type=="last" && "Last"}
                {this.props.type=="live" && "Live"}
            </Col>
        </Row>
        <Row className="last-line-res">
            <Col xs={1} className="line-res-cont-rank">   
            {this.props.type=="last"?<span className="line-res-rank">{entry.rank}</span>:null}
            </Col>
            <Col xs={11} className="line-res-cav-horse">
                {entry.Cavalier}<br/><span className="line-res-horse">{entry.headNb} - {entry.Cheval}</span>
            </Col>
        </Row>
        {this.props.type=="last"&&
        <Row className="">
            <Col xs={12} className="alignRight">
                <span className="line-res-res">
                {entry.status && entry.status!=''?
                    this.statut(entry) :
                    this.mark(entry)
                }
                </span>
            </Col>
        </Row>
        }
        {this.props.type=="live" && datasLive &&        
        <Row className="">
            <Col xs={12} className="alignRight">
                <span className="line-res-res">{datasLive.MoyenneLive && <>{datasLive.MoyenneLive} %&nbsp;({datasLive.ClassementLive}.)</>}</span>
            </Col>
        </Row>
}
    </Container>}</>
)
}
}
export default TableLine;
