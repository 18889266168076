import React from "react";
import NumberFormat from 'react-number-format';

class ResultSO02Item extends React.Component {
    statut = () => {
        return(this.props.entry.status)
    }
    mark = () => {
        return (<><NumberFormat 
                    value={this.props.entry.result.PenTotal}
                    displayType={'text'}
                    decimalScale={0}
                />&nbsp;/&nbsp;
                <NumberFormat 
                    value={this.props.entry.result.Temps2}
                    displayType={'text'}
                    decimalScale={2}
                />
        </>)
    }
    render(){
    
    return(
        <>
        {this.props.entry?
        <span className="line-res-res">
            {this.props.entry.status?
                this.statut() :
                this.mark()
            }
        </span>
        :null}
        </>
)
}
}
export default ResultSO02Item;