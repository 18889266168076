import './App.css';
import React from "react";
import Table from './Table.js'
import Logo from './Logo.js'
import Test from './Test.js'
import TableGiant from './TableGiant.js'
import { Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="fill-window">
      <div className="main">
          
          <Routes>
            <Route path="/" element={<Logo/>} />
            <Route path="/event/:idEvent/arena/:idArena" element={<Table/>} />
            <Route path="/event/:idEvent/arena/:idArena/giant" element={<TableGiant/>} />
            <Route path="/test" element={<Test/>} />
          </Routes>
      </div>
    </div>
  );
}

export default App;
