import React from "react";

class ResultDrJudges extends React.Component {

    _judgeResult = (result,lettre,cote="milieu")=>{
        if (cote=="droite"){
            return(<>{lettre + ': '}<span style={{fontWeight:"bold"}}>{result["Juge"+lettre]}</span>{' (' + result["Class"+lettre] + '.)'}&nbsp;&nbsp;|&nbsp;&nbsp;</>)
        } else if (cote=="gauche") {
            return(<>&nbsp;&nbsp;|&nbsp;&nbsp;{lettre + ': '}<span style={{fontWeight:"bold"}}>{result["Juge"+lettre]}</span>{' (' + result["Class"+lettre] + '.)'}</>)
        } else {
            return(<>{lettre + ': '}<span style={{fontWeight:"bold"}}>{result["Juge"+lettre]}</span>{' (' + result["Class"+lettre] + '.)'}</>)
        }
    }

    render(){
    var entry = this.props.entry

    return(
        <>
        {entry?
        <span className='judges'>
            {entry.result.JugeE>0 && this._judgeResult(entry.result,"E","droite")}
            {entry.result.JugeH>0 && this._judgeResult(entry.result,"H","droite")}
            {entry.result.JugeC>0 && this._judgeResult(entry.result,"C")}
            {entry.result.JugeM>0 && this._judgeResult(entry.result,"M","gauche")}
            {entry.result.JugeB>0 && this._judgeResult(entry.result,"B","gauche")}
        </span>
        :null}
        </>
)
}
}
export default ResultDrJudges;