import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import ResultDrItem from './ResultDrItem'

class TableLine extends React.Component {

    render(){
        const styleBordRed = {borderBottomColor: 'red'}
    return(
        <>
        {this.props.entry?
        <Row className="line-res" style={this.props.pageTot>1 && this.props.ind == 4 ? styleBordRed: null}>
            <Col xs={1} className="line-res-cont-rank alignCenter">   
                <span className="line-res-rank">{this.props.entry.entryRank>0?this.props.entry.entryRank+'.':null}</span>
                {this.props.liveId.last == this.props.entry.idWST && <><div><Badge style={{fontSize: 16}} bg="success">LAST</Badge></div></> }
                {this.props.liveId.live == this.props.entry.idWST && <><div><Badge style={{fontSize: 16}} bg="danger">LIVE</Badge></div></> }
            </Col>
            <Col xs={3} className="line-res-cav-horse">
                {this.props.entry.rider}<br/><span className="line-res-horse">{this.props.entry.headNb} - {this.props.entry.horse}</span>
                
            </Col>
            <Col xs={8} className="alignCenter">
                <ResultDrItem entry={this.props.entry} afficheDetails={true} />
            </Col>
        </Row>
        :null}
        </>
)
}
}
export default TableLine;
