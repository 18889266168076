import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TableLine extends React.Component {

    render(){
    return(
        <Container fluid className="table-last">
        <Row >
            <Col xs={12} className="last-title">   
                {this.props.type=="last"?"Last":null}
                {this.props.type=="live"?"Live":null}
            </Col>
        </Row>
        <Row className="last-line-res">
            <Col xs={1} className="line-res-cont-rank">   
            {this.props.type=="last"?<span className="line-res-rank">99.</span>:null}
            </Col>
            <Col xs={11} className="line-res-cav-horse">
                Martin PEREAL<br/><span className="line-res-horse">325 - Petit poney détonnant XXXXXXXX</span>
            </Col>
        </Row>
        {this.props.type=="last"?
        <Row className="">
            <Col xs={12} className="alignRight">
                <span className="line-res-res">0-0 / 99.99</span>
            </Col>
        </Row>
        :null}
    </Container>
)
}
}
export default TableLine;
