import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResultSO01Item from './ResultSO01Item'
import ResultSO02Item from './ResultSO02Item'

class TableLine extends React.Component {

    render(){
    return(
        <>
        {this.props.entry?
        <Row className="line-res">
            <Col xs={1} className="line-res-cont-rank">   
                <span className="line-res-rank">{this.props.entry.entryRank>0?this.props.entry.entryRank+'.':null}</span>
            </Col>
            <Col xs={8} className="line-res-cav-horse">
                {this.props.entry.rider}<br/><span className="line-res-horse">{this.props.entry.headNb} - {this.props.entry.horse}</span>
                
            </Col>
            <Col xs={3} className="alignCenter">
                {this.props.template == "result_ju_01.html.twig" && <ResultSO01Item entry={this.props.entry} />}
                {this.props.template == "result_ju_02.html.twig" && <ResultSO02Item entry={this.props.entry} />}
            </Col>
        </Row>
        :null}
        </>
)
}
}
export default TableLine;
