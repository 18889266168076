import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class ResultDrJudgesTable extends React.Component {

    _judgeResult = (result,lettre,cote="milieu")=>{
        if (cote=="droite"){
            return(<>{lettre + ': ' + result["Juge"+lettre] + ' (' + result["Class"+lettre] + '.)'}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</>)
        } else if (cote=="gauche") {
            return(<>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{lettre + ': ' + result["Juge"+lettre] + ' (' + result["Class"+lettre] + '.)'}</>)
        } else {
            return(<>{lettre + ': '}<span style={{fontWeight:"bold"}}>{result["Juge"+lettre]}</span>{' (' + result["Class"+lettre] + '.)'}</>)
        }
    }

    render(){
    var entry = this.props.entry

    return(
        <>
        {entry?
        <span className='judges'>
            <Row>
                <Col>
                    {entry.result.JugeH>0 && this._judgeResult(entry.result,"H","milieu")}
                </Col>
                <Col>
                    {entry.result.JugeC>0 && this._judgeResult(entry.result,"C","milieu")}
                </Col>
                <Col>
                    {entry.result.JugeM>0 && this._judgeResult(entry.result,"M","milieu")}
                </Col>
            </Row>
            <Row>
                <Col>
                    {entry.result.JugeE>0 && this._judgeResult(entry.result,"E","milieu")}
                </Col>
                <Col>
                    
                </Col>
                <Col>
                    {entry.result.JugeB>0 && this._judgeResult(entry.result,"B","milieu")}
                </Col>
            </Row>
        </span>
        :null}
        </>
)
}
}
export default ResultDrJudgesTable;