import React from "react";
import moment from 'moment/min/moment-with-locales'

class Test extends React.Component {
    constructor(props) {
        super(props);
        this.intervalTime = null;
        this._ws = null;
        this._url = "wss://ws.wstapi.fr"    
        this.isConnected = false;
        this.timerReconnect = null;
        this.state = { datas: [],time:"99:99:99" };    
        
        //this._url = "ws://207.154.217.4:3001/"    
      }
    
    componentDidMount(){
        this.intervalTime = setInterval(this.afficheHeure,10)
        this.connectWS()
        this.timerReconnect = setInterval(()=>{
          if (!this.isConnected){
            if (this._ws){
              this._ws.close()
              this._ws = null
            }
            this.isConnected = true
            this.connectWS()
          }
        },5000)
    }



    connectWS = () => {
      const ws = new WebSocket(this._url)
      this._ws = ws
      ws.onopen = () => {
        console.log('Connected to the server')
        var message = {
          action: 'subscribe',
          payload: {
            topic: "test",
          },
        }
        message = JSON.stringify(message)
        this._ws.send(message)
        this.isConnected = true
      }
      // listen a message from the server
      ws.onmessage = (message) => {
        const jsonMessage = JSON.parse(message.data)
        const action = jsonMessage.action
        const payload = jsonMessage.payload
  
        switch (action) {
          case 'me':
            break
          case 'publish':
            //this.emitter.emit(`subscribe_topic_${payload.topic}`, payload.message)
            // let emit this to subscribers
            break
          default:
            break
        }
      }
      ws.onerror = (err) => {
        console.log('unable connect to the server', err)    
        this.isConnected = false
      }
      ws.onclose = () => {
        console.log('Connection is closed') 
        this.isConnected = false
      }
    }

    afficheHeure=()=>{
        this.setState({time:moment().format('HH:mm:ss.S')})
    }

    componentWillUnmount(){
        console.log("CLOSE")
        clearInterval(this.intervalTime);
    }
    btn = () => {
      var msg = {
        action: 'publish',
        payload: {
          topic: "test",
          message: "{msg:toto}",
        },
      }
      msg = JSON.stringify(msg)
      if(this._ws){
      if(this._ws.readyState == 1){
        this._ws.send(msg)
      }
    }
    }

    render() {
      return (
          <>
        <div className="title">
            <br/>
            <img alt="logo" width="600px" src="/img/wst.svg"/>
            <br/><br/>
            <button onClick={this.btn}>TEST</button>
        </div>
        <div className="time">
            {this.state.time}
        </div>
        
        </>
      );
    }
  }

  export default Test;
