import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TableLine from "./TableLine.js";
import TableLineLast from "./TableLineLast.js";
import ResultDrItem from './ResultDrItem'
import './Giant_full.css'

function TableSsDRGiant(props) {
    if (props){
        var datas = props.datas;
        var cols = props.cols;
    }
    var liveId = {"live":0,"last":0}
    var champLive
    var entry

    return (
        <>
        <div style={{ backgroundColor: "transparent",height:'100%',width:'100%',backgroundImage: "url(/img/giant_full/ranking.png)"}}>
            {datas.liste &&
            <>
            <div className="giant_full-ranking-title">
                <div className="giant_full-ranking-title-text">
                    CDI4* Le Mans Boulerie Jump
                </div>
            </div>
            <div className="giant_full-ranking-lines">
                {datas.liste.map((d,index)=>{
                    if (index < 5){
                        return(
                            <div key={index} className="giant_full-ranking-line" style={{ backgroundRepeat:'no-repeat'}}>
                                <div className="giant_full-ranking-line-rank">
                                    {d.entryRank}.
                                </div>
                                <div className="giant_full-ranking-line-rider">
                                    {d.rider}
                                </div>
                                <div className="giant_full-ranking-line-horse">
                                    {d.horse}
                                </div>
                                <div className="giant_full-ranking-line-res">
                                    <ResultDrItem entry={d} afficheDetail={false}/>
                                </div>
                                <div className="giant_full-ranking-flag">
                                    {d.nation &&
                                        <img alt="drapeau" width="150px" src={"/img/flags/"+d.nation+'.png'}/>
                                    }
                                </div>
                            </div>        
                        )        
                    } else {
                        return (null)
                    }
                })}
            </div> 
            </>   
            }    
        </div>
    </>
      );
    
  }

  export default TableSsDRGiant;
