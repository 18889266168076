import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TableLine from "./TableLine.js";
import TableLineLast from "./TableLineLast.js";


function TableSsDR(props) {
    if (props){
        var datas = props.datas;
        var cols = props.cols;
        var col1 = cols.col1;    
        var datasLive = props.datasLive
        var pageTot = props.pageTot
    }
    var liveId = {"live":0,"last":0}
    var champLive
    var entry
    if (datas.competition.champLive){
        champLive = JSON.parse(datas.competition.champLive)
        if (champLive["last"]){
            entry = JSON.parse(champLive["last"])
            if (entry){
                entry = entry[0]
                if (entry){
                    liveId.last = entry.idWST
                }
            }
        }
        if (champLive["live"]){
            entry = JSON.parse(champLive["live"])
            if (entry){
                entry = entry[0]
                if (entry){
                    liveId.live = entry.idWST
                }
            }
        }

    }

    var nbJudges = JSON.parse(datas.competition.judges).length
    return (
          <>
         <Container fluid className="leaders">
            <Row>
                <Col xs={8}>
                <Container fluid className="table-results">
                    {col1.map((d,index)=>{
                            return(
                                <TableLine key={index} ind={index} entry={d} template={datas.template} liveId={liveId} pageTot={pageTot} />
                            )
                        })}
                </Container>
                </Col>
                <Col xs={4}>
                    <TableLineLast type={"last"} nbjudges={nbJudges} champLive={datas.competition.champLive}/>
                    <TableLineLast type={"live"} champLive={datas.competition.champLive} datasLive={datasLive}/>
                    <Container fluid className="table-last">
                        <Row >
                            <Col className="stats-title">   
                                Entries
                            </Col>
                        </Row>
                        <Row className="last-line-res">
                            <Col className="stats-core stats-core-padd">   
                                {datas.competition.nbEntries}
                            </Col>
                        </Row>
                    </Container>
                </Col>

            </Row>
        </Container> 
        </>
      );
    
  }

  export default TableSsDR;
