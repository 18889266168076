import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TableLine from "./TableLine.js";
import TableLineLast from "./TableLineLast.js";


function TableSsSO(props) {
    if (props){
        var datas = props.datas;
        var cols = props.cols;
        var col1 = cols.col1;    
        var col2 = cols.col2;    
    }
    return (
          <>
         <Container fluid className="leaders">
            <Row>
                <Col xs={4}>
                <Container fluid className="table-results">
                    {col1.map((d,index)=>{
                            return(
                                <TableLine key={index} entry={d} template={datas.template} />
                            )
                        })}
                </Container>
                </Col>
                <Col xs={4}>
                <Container fluid className="table-results">
                    {col2.map((d,index)=>{
                            return(
                                <TableLine key={index} entry={d} template={datas.template} />
                            )
                        })}
                </Container>
                </Col>
                <Col xs={4}>
                    <TableLineLast type={"last"}/>
                    <TableLineLast type={"live"}/>
                    <Container fluid className="table-last">
                        <Row >
                            <Col xs={3} className="stats-title">   
                                Clear
                            </Col>
                            <Col xs={3} className="stats-title">   
                                Left
                            </Col>
                            <Col xs={3} className="stats-title">   
                                T-A
                            </Col>
                            <Col xs={3} className="stats-title">   
                                Finish
                            </Col>
                        </Row>
                        <Row className="last-line-res">
                            <Col xs={3} className="stats-core">   
                                32<div className="stats-core-small">(100%)</div>
                            </Col>
                            <Col xs={3} className="stats-core stats-core-padd">   
                                120/120
                            </Col>
                            <Col xs={3} className="stats-core stats-core-padd">   
                                75"
                            </Col>
                            <Col xs={3} className="stats-core stats-core-padd">   
                                99:99
                            </Col>
                        </Row>
                    </Container>
                </Col>

            </Row>
        </Container> 
        </>
      );
    
  }

  export default TableSsSO;
