import React from "react";
import NumberFormat from 'react-number-format';
import ResultDrJudges from './ResultDrJudges'
import ResultDrJudgesTable from './ResultDrJudgesTable'
class ResultDrItem extends React.Component {
    constructor(props) {
        super(props);
        this.type = 'line';
    }

    componentDidMount(){
        if (this.props.type){
            this.type = this.props.type
        }
    }

    statut = () => {
        return(this.props.entry.status)
    }
    mark = () => {
        if (this.props.entry.result){
            return (<>{this.props.entry.result.Moyenne > 0 ? <><NumberFormat 
                value={this.props.entry.result.Moyenne}
                displayType={'text'}
                decimalScale={3}
            /> %</>:<em>{this.props.entry.startTime}</em>}
            <br/>
            {this.props.afficheDetails &&
            <>
            {this.type == 'line'?
                <ResultDrJudges entry={this.props.entry} />
            :
                <ResultDrJudgesTable entry={this.props.entry} />
            }
            </>}
            </>)
        } else {
            return (null)
        }
    }
    render(){
    
    return(
        <>
        {this.props.entry?
        <span className={this.props.afficheDetails?"":"giant_full_line-res-res"}>
            {this.props.entry.status && this.props.entry.status!=''?
                this.statut() :
                this.mark()
            }
        </span>
        :null}
        </>
)
}
}
export default ResultDrItem;