import React, { useState, useEffect } from "react";
import {getCompetitionList,getParamsArena} from './Datas.js'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TableSsDR from"./DR/TableSsDR"
import TableSsDRGiant from"./DR/TableSsDRGiant"
import TableSsSO from"./SO/TableSsSO"
import moment from 'moment/min/moment-with-locales'
import { useParams } from 'react-router-dom';
import {useInterval} from './Functions'
import Logo from './Logo'


function TableGiant() {
    const[time,setTime] = useState("99:99:99");
    const[wWidth,setWWidth] = useState(0);
    const {idEvent,idArena} = useParams();
    const [datas,setDatas] = useState();
    const [datasLive,setDatasLive] = useState();
    const [pageActive,setPageActive] = useState(1);
    const [pageTot,setPageTot] = useState(1);
    var eventSource = null; 
    var _ws = null;
    var _url = "wss://ws.wstapi.fr"    
    const [isConnected,setIsConnected] = useState(false);
    var timerReconnect = null;


    useInterval(() => {
        setTime(moment().format('HH:mm:ss'));
      }, 100);

    useInterval(() => {
        loadActiveEpreuve(); //permet de verifier l'épreuve en cours
    }, 60000);

    useInterval(() => {
      pageSuivante(); //permet de verifier l'épreuve en cours
    }, 20000);

    const pageSuivante = () => {
      var pgAct = pageActive + 1
      if (pgAct>pageTot){
        pgAct = 1
      }
      setPageActive(pgAct)
    }
    const pageTotale = (discipline,count) => {
      if (discipline == "Dressage") {
        var pageTot = Math.ceil((count-5)/5)
      } else {
        var pageTot = Math.ceil((count-10)/10)
      }
      setPageTot(pageTot)
    }

    useEffect(() => { //initialisation
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        loadActiveEpreuve();
        connectWS()
        timerReconnect = setInterval(()=>{
          if (!isConnected && datas){
            if (_ws){
              _ws.close()
              _ws = null
            }
            setIsConnected (true)
            connectWS(datas.competition.id)
          }
        },5000)

        return function cleanup() {
            console.log('close')
            window.removeEventListener('resize', updateWindowDimensions);    
          };        
    },[]);

    // useEffect(()=> {
    //     submitMercure();
    // })

    const connectWS = (idComp) => {
      if (!idComp){return false;}
      const ws = new WebSocket(_url)
      _ws = ws
      ws.onopen = () => {
        console.log('Connected to the server')
        var message = {
          action: 'subscribe',
          payload: {
            topic: "comp-live-"+idComp,
          },
        }
        message = JSON.stringify(message)
        ws.send(message)
        setIsConnected (true)
      }
      // listen a message from the server
      ws.onmessage = (message) => {
        const jsonMessage = JSON.parse(message.data)
        const action = jsonMessage.action
        const payload = jsonMessage.payload
        switch (action) {
          case 'me':
            break
          case 'publish':
              if(idComp){
                switch (payload.topic) {
                  case 'comp-live-'+idComp:
                    setDatasLive(JSON.parse(payload.message))
                  break
                }  
              }
            break
          default:
            break
        }
      }
      ws.onerror = (err) => {
        console.log('unable connect to the server', err)    
        setIsConnected (false)
      }
      ws.onclose = () => {
        console.log('Connection is closed') 
        setIsConnected (false)
      }
    }



    const loadActiveEpreuve=()=>{
        getParamsArena(idEvent,idArena).then(data=>{
            if (data){
                var go=true
                if (datas){
                    if (data.data.idComp == datas.competition.id){
                        go = false
                    }
                }
                if (go==true){
                    getCompetitionList(data.data.route,data.data.idComp,idEvent).then(list=>{
                        if (list){
                            setDatas(list.data);
                            pageTotale(list.data.competition.label.discipline.name,list.data.liste.length)
                            submitMercure(list.data);
                            if (_ws){
                              _ws.close()
                              _ws = null
                            }
                            connectWS(data.data.idComp)    
                        }
                    })    
                }
            }
        }
        ).catch((error) => console.log(error));

    }

    const updateWindowDimensions=()=>{
        setWWidth(window.innerWidth)
        console.log('resize')
      }
    var scale = wWidth/1920;
    var styles = { 
        transform:'scale('+scale+')',width:'1920px',height:'1080px',transformOrigin: 'top left'
    };

    const submitMercure=(datasMercure)=> {
        if (datasMercure){
            eventSource = new EventSource('https://mercure.wstapi.fr/.well-known/mercure?topic=/app-live/'+datasMercure.competition.id);
            console.log("INIT MERCURE")
            // Grab all events with the type of 'message'
            eventSource.addEventListener('message', (data) => {
              if (data.type == 'message'){
                console.log("DEBUT UPDATE MERCURE")
                if (data.data){
                  const json = JSON.parse(data.data)
                  if (json.data){
                    setDatas(json.data);
                    pageTotale(json.data.competition.label.discipline.name,json.data.liste.length)
                  }
                  console.log("FIN UPDATE MERCURE")
                }
              }
            });
        }
      }
    
      const closeMercure = ()=>{
        if (eventSource != undefined) {
          console.log("DESTROY MERCURE")
          eventSource.removeAllListeners();
          eventSource.close();  
        }
      }

    if (datas){
        var col1 = [];
        var col2 = [];
        if(datas.competition.label.discipline.name=="Dressage"){
          for (let i = 0 ; i < 5; i++) {
            if (datas.liste[i]){
                col1.push(datas.liste[i])
            }
          }
          for (let i = (5+(pageActive-1)*5) ; i < (10+(pageActive-1)*5); i++) {  
            if (datas.liste[i]){
                col1.push(datas.liste[i])
            }
          }

        } else {
          for (let i = 0 ; i < 10; i++) {
            if (datas.liste[i]){
                col1.push(datas.liste[i])
            }
          }
          for (let i = 10 ; i < 20; i++) {
              if (datas.liste[i]){
                  col2.push(datas.liste[i])
              }
          }
        }
        var cols = [];
        cols["col1"] = col1;
        cols["col2"] = col2;
    }
    return (
          <>
          {datas?
          <div style={styles}>
          

            {datas.competition.label.discipline.name=="Dressage" && <TableSsDRGiant cols={cols} datas={datas} datasLive={datasLive} pageTot={pageTot} />}

        </div>:<Logo no_data={true} />}
        </>
      );
    
  }

  export default TableGiant;
