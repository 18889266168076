import axios from 'axios';
var urlMain = 'https://results.wstapi.fr/api/'


export function getCompetitionList(route,idComp,idEvent) {
  const url = urlMain + 'events/competition'
  let formdata = new FormData();
  formdata.append("route", route)
  formdata.append("idComp", idComp)
  formdata.append("idEvent", idEvent)

  return axios.post(url,formdata,
    {   
      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
      }})
    .then((response) => response.data)
    .catch(function (error) {
      console.log(error);
    });
}

export function getParamsArena(idEvent,idArena) {

    const url = urlMain + 'events/arena/params'
    let formdata = new FormData();
    formdata.append("idEvent", idEvent)
    formdata.append("idArena", idArena)
    return axios.post(url,formdata,
      {   
        timeout: 10000,
        headers: {
          'Content-Type': 'multipart/form-data',
        }})
      .then((response) => response.data)
      .catch(function (error) {
        console.log(error);
      });
}
 
 
